import React, { useMemo } from 'react';
import dynamic from 'next/dynamic';
import type { SxProps } from '@mui/system';
import { useWindowSize } from 'react-use';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { useTranslation } from 'next-i18next';

import Typography from 'src/components/ui/Typography';
import Link from 'src/components/ui/Link';
import Button from 'src/components/ui/Button';
import Icon from 'src/components/ui/Icon';
import LanguageToggler from 'src/components/ui/LanguageToggler';
import { colours } from 'src/constants/colours';
import { breakpoints } from 'src/constants/breakpoints';
import useUser from 'src/hooks/useUser';
import { useLegalLinksQuery } from 'src/hooks/__generated__/queries';
import getCurrentVersion from 'src/utils/version';
import { routePathHome } from 'src/constants/router';
import { styledComponent, styledTag } from 'src/utils/styled';
import { AnalyticsEvent } from 'src/utils/analytics';
import useDynamicLogEvents from 'src/hooks/useDynamicLogEvents';

const Logo = dynamic(() => import('src/assets/logos/footer-logo.svg'), { ssr: false });

const StyledCard = styledComponent(Card)`
    background: ${colours.primary.O04};
    border-radius: 0;
`;

StyledCard.defaultProps = {
    elevation: 0,
};

const StyledCardContent = styledComponent(CardContent)`
    padding: 0.5rem;
`;

const StyledTypography = styledComponent(Typography)`
    font-size: 0.9rem;
    font-weight: bold;
`;

const StyledLogo = styledComponent(Logo)`
    display: block;
    width: 5.125rem;
    height: 1.375rem;
`;

const StyledCopyright = styledComponent(Typography)`
    margin-top: 0.5rem;
    color: ${colours.primary.O72};
`;

const StyledVersion = styledComponent(Typography)`
    margin-top: 0.5rem;
    color: ${colours.primary.O72};
`;

const StyledLink = styledTag('a')`
    text-decoration: none;
    display: inline;
    &:hover {
        text-decoration: underline;
    }
`;

const styledButton: SxProps = {
    width: '11rem',
    background: colours.secondary.main,
};

const styledMobileButton: SxProps = {
    background: colours.secondary.main,
    width: 'calc(100% - 3rem)', // 3rem = margin
};

interface Props {
    isOnSide?: boolean;
    variant?: 'compact' | 'large';
}

const Footer: React.FC<Props> = ({ isOnSide, variant = 'large' }) => {
    const { t } = useTranslation(['common']);
    const currentUser = useUser();
    const { data: links } = useLegalLinksQuery();
    const { logEventDynamic } = useDynamicLogEvents();

    const { width } = useWindowSize();

    const { isNotDesktopLarge, isTabletLandscape, isTabletPortrait, isMobile } = useMemo(
        () => ({
            isNotDesktopLarge: width < breakpoints.desktopLarge,
            isTabletLandscape: width > breakpoints.tabletLandscape && width < breakpoints.desktopLarge,
            isTabletPortrait: width < breakpoints.tabletLandscape && width > breakpoints.tabletPortrait,
            isMobile: width < breakpoints.tabletPortrait,
        }),
        [width],
    );

    const version = getCurrentVersion();
    const currentVersion = (
        <StyledVersion variant="caption" data-target-id="current-version">
            {' '}
            {version}
        </StyledVersion>
    );

    const logo = (
        <Link data-target-id="logo" href={routePathHome}>
            <StyledLogo />
        </Link>
    );

    const langSwitcher = (
        <Box display="flex" justifyContent="flex-end" data-target-id="lang-switcher">
            {!isOnSide && !isNotDesktopLarge && (
                <Box display="flex" justifyContent="flex-start" mr="4rem">
                    <Button sx={styledButton} startIcon={<Icon name="User" />} href={String(links?.contact)}>
                        {t('common:contactUs')}
                    </Button>
                </Box>
            )}
            <LanguageToggler />
        </Box>
    );

    const contactButton = (
        <Box display="flex" justifyContent="flex-end" width="15rem" data-target-id="contact-button">
            <Button
                sx={styledButton}
                startIcon={<Icon name="User" />}
                href={String(links?.contact)}
                onClick={() => logEventDynamic(AnalyticsEvent.ContactUs)}
            >
                {t('common:contactUs')}
            </Button>
        </Box>
    );

    const contactMobileButton = (
        <Box display="flex" justifyContent="center" data-target-id="contact-mobile-button">
            <Button
                sx={styledMobileButton}
                size="medium"
                startIcon={<Icon name="User" size="1.5rem" />}
                href={String(links?.contact)}
            >
                <StyledTypography>{t('common:contactUs')}</StyledTypography>
            </Button>
        </Box>
    );

    const linkContent = (
        <Box>
            <StyledLink
                data-target-id="invite-link"
                href={process.env.DOWNLOAD_URL ?? 'https://link.woopen.com/invite'}
                target="_blank"
                rel="noreferrer noopener"
                onClick={() => logEventDynamic(AnalyticsEvent.DownloadApp)}
            >
                <Typography variant="caption" color="primary">
                    {t('common:footer.downloadApp')}
                </Typography>
            </StyledLink>
            {' | '}
            <StyledLink
                data-target-id="conditions"
                href={String(
                    currentUser?.isProfessionnal ? links?.generalConditionsPro : links?.generalConditionsBasic,
                )}
                target="_blank"
                rel="noreferrer noopener"
                onClick={() => logEventDynamic(AnalyticsEvent.GeneralConditions)}
            >
                <Typography variant="caption" color="primary">
                    {t('common:footer.conditions')}
                </Typography>
            </StyledLink>
            {' | '}
            <StyledLink
                data-target-id="legal-notice"
                href={String(links?.legalNotice)}
                target="_blank"
                rel="noreferrer noopener"
                onClick={() => logEventDynamic(AnalyticsEvent.LegalNotice)}
            >
                <Typography variant="caption" color="primary">
                    {t('common:footer.legalNotice')}
                </Typography>
            </StyledLink>
            {' | '}
            <StyledLink
                data-target-id="privacy-policy"
                href={String(links?.privacyPolicy)}
                target="_blank"
                rel="noreferrer noopener"
                onClick={() => logEventDynamic(AnalyticsEvent.PrivacyPolicy)}
            >
                <Typography variant="caption" color="primary">
                    {t('common:footer.privacy')}
                </Typography>
            </StyledLink>
            {' | '}
            <StyledLink
                data-target-id="cookie-policy"
                href={String(links?.cookiePolicy)}
                target="_blank"
                rel="noreferrer noopener"
                onClick={() => logEventDynamic(AnalyticsEvent.CookiesPolicy)}
            >
                <Typography variant="caption" color="primary">
                    {t('common:footer.cookiePolicy')}
                </Typography>
            </StyledLink>
        </Box>
    );

    const copyRight = (
        <StyledCopyright
            data-target-id="copyright"
            variant="caption"
            dangerouslySetInnerHTML={{
                __html: t('common:footer.copyright', { year: new Date().getFullYear() }),
            }}
        />
    );

    if (variant === 'compact' || isNotDesktopLarge) {
        return (
            <StyledCard>
                <StyledCardContent>
                    <Grid container>
                        <Grid xs={6} sm={6} md={6} lg={6} xl={6} item>
                            {logo}
                        </Grid>
                        <Grid xs={6} sm={6} md={6} lg={6} xl={6} item>
                            {langSwitcher}
                        </Grid>
                        <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
                            <Box mt="1rem" display="flex" flexDirection="row">
                                <Box my={1}>{linkContent}</Box>
                                {isTabletLandscape && !isOnSide && <Box marginLeft="auto">{contactButton}</Box>}
                            </Box>
                        </Grid>
                        <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
                            <Box mt="1rem" display="flex" flexDirection="row">
                                {copyRight}
                                {currentVersion}
                                {isTabletPortrait && !isOnSide && <Box marginLeft="auto">{contactButton}</Box>}
                            </Box>
                        </Grid>
                    </Grid>
                    {isMobile && !isOnSide && <Box mt="1rem">{contactMobileButton}</Box>}
                    {isOnSide && <Box mt="1rem">{contactMobileButton}</Box>}
                </StyledCardContent>
            </StyledCard>
        );
    }

    return (
        <StyledCard>
            <StyledCardContent>
                <Container maxWidth="xl">
                    <Grid container>
                        <Grid xs={2} sm={2} md={2} lg={2} xl={2} item>
                            {logo}
                            {copyRight}
                        </Grid>
                        <Grid xs={8} sm={8} md={8} lg={8} xl={8} item>
                            <Box display="flex" alignItems="center" justifyContent="center" mt={1}>
                                {linkContent}
                            </Box>
                        </Grid>
                        <Grid xs={2} sm={2} md={2} lg={2} xl={2} item>
                            {langSwitcher}
                            <Box display="flex" justifyContent="flex-end">
                                {currentVersion}
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </StyledCardContent>
        </StyledCard>
    );
};

export default Footer;
